.banner_wrapper {
  display: flex;
  width: 100%;
  padding: 100px 0 125px;
  background-color: var(--bgColor);
  background-size: cover;
  justify-content: center;
  align-items: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headline);
}

.icons {
  color: var(--headline);
}

.icons:hover {
  color: var(--primaryColor);
  transform: translateY(-15%);
  transition: transform 0.5s;
  cursor: pointer;
}

@media (max-width: 1230px) {
  .banner_wrapper .banner-title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .banner_wrapper {
    padding: 100px 0 100px;
  }
  .banner_wrapper .row {
    flex-direction: column-reverse;
  }
  .banner_wrapper .learn-more-btn-section {
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .banner_wrapper .banner-title {
    font-size: 36px;
  }
}
@media (max-width: 500px) {
  .shape-fill {
    display: none;
  }
}

.scroll__down {
  position: absolute;
  bottom: 5rem;
  left: 0;
  width: 100%;
}

.home__scroll-name {
  font-size: 1rem;
  color: var(--headline);
  font-family: var(--mainText);
}

.mouse {
  border: 2px solid var(--headline);
  display: block;
  height: 1.8rem;
  width: 1.25rem;
  margin: auto;
  margin-top: 0.75rem;
  border-radius: 1rem;
  position: relative;
}

.wheel {
  background-color: var(--headline);
  border-radius: 100%;
  width: 0.25rem;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-60%);
  animation: ani-mouse 2s linear infinite;
}

@keyframes ani-mouse {
  0% {
    top: 29%;
  }

  25% {
    top: 50%;
  }

  50% {
    top: 50%;
  }

  100% {
    top: 29%;
  }
}

@media (max-width: 1070px) {
  .scroll__down {
    display: none;
  }
}

@media (max-width: 991px) {
  .scroll__down {
    bottom: 10rem;
  }
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.imageAnimate {
  animation: profile_animate 8s ease-in-out infinite 1s;
  background-color: var(--primaryColor);
  box-shadow: inset 0 0 0 6px rgba(255, 255, 255, 0.626);
}

@keyframes profile_animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
